export default {
    auth: store => (to, from, next) => {
        if (!store.getters['auth/hasToken'])
            return next({name: 'login'})

        fetchUser(store)
            .then(() => restrictRoutes(store, to, from, next))
            .catch(() => {
                store.commit('auth/userLogout')
                return next({name: 'login'})
            })
    },
}

const restrictRoutes = (store, to, from, next) => {
    if (store.getters['auth/getCurrentUser']) {
        if (!to.name.startsWith(store.getters['auth/getCurrentUser'].role.value))
            return next(false)

        if (store.getters['auth/isClient']) {
            if (!store.getters['auth/clientCompletedQuiz'] && to.name !== 'client.questionnaire')
                return next({name: 'client.questionnaire', replace: true})

            if (store.getters['auth/clientCompletedQuiz'] && to.name === 'client.questionnaire')
                return next(false)
        }

        if (store.getters['auth/isDoctor']) {
            if (from.name !== 'doctor.register' && from.name !== 'doctor.questionnaire' && from.name === 'doctor.signature') {
                if (!store.getters['auth/clientCompletedQuiz']) return to.name === 'doctor.questionnaire' ? next() : next({name: 'doctor.questionnaire', replace: true})
                if (!store.getters['auth/hasSignature']) return to.name === 'doctor.signature' ? next() : next({name: 'doctor.signature', replace: true})
            }

            if (store.getters['auth/clientCompletedQuiz'] && to.name === 'doctor.questionnaire')
                return next(false)
        }
    }

    return next()
}

const fetchUser = (store) => {
    return new Promise((resolve, reject) => {
        store.dispatch('auth/fetchAuthenticatedUser')
            .then(() => {
                resolve()
            })
            .catch(err => {
                err.response.status === 401 ?
                    refreshAuth(store).then(res => {
                        resolve(res)
                    }).catch(er => {
                        reject(er)
                    }) :
                    reject(err)
            })
    })
}

const refreshAuth = (store) => {
    return new Promise((resolve, reject) => {
        store.dispatch('auth/refreshToken')
            .then(res => {
                if (res.data.error) reject(res)

                store.dispatch('auth/fetchAuthenticatedUser')
                    .then(() => {
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
            .catch(err => {
                reject(err)
            })
    })
}
