import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/modules/auth";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {auth},
    state: () => ({
        //Common
        dialogActive: false,

        //Snackbar
        snackBarModel: false,
        snackBarText: '',
        snackBarColor: 'success',
        snackBarCloseColor: 'grey lighten-1',
    }),
    mutations: {
        showSnackBar(state, message = '') {
            state.snackBarText = message.text || message
            state.snackBarColor = message.color || 'grey darken-3'
            state.snackBarCloseColor = message.closeColor || 'grey lighten-1'
            state.snackBarModel = true
        },

        setSnackBarModel(state, value) {
            state.snackBarModel = value
        },

        hideSnackBar(state) {
            state.snackBarModel = false
        },

        setDialogActive(state, active = false) {
            state.dialogActive = active
        },
    },
})
