import axios from 'axios';
import storageService from '@/services/storageService';

export class HttpService {

    constructor() {
        this.headers = {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        }
        this.axios = axios.create({
            baseURL: process.env.VUE_APP_BASE_URL_API
        });

        this.axios.interceptors.request.use(
            config => {
                if (storageService.getToken()) config.headers['Authorization'] = 'Bearer ' + storageService.getToken()
                return Promise.resolve(config)
            },
            err => {
                return Promise.reject(err)
            }
        );
    }

    get(url, params) {
        return this.axios.get(url, params)
    }

    post(url, data) {
        return this.axios.post(url, data, {crossdomain: true})
    }

    put(url, data) {
        return this.axios.put(url, data)
    }

    delete(url, params) {
        return this.axios.delete(url, params)
    }

    getFile(url) {
        return this.axios.get(url, { responseType: 'blob' })
    }

    postFile(url, data) {
        return this.axios.post(url, data, {headers: {'Content-Type': 'multipart/form-data'}})
    }

    putFile(url, data) {
        data.append('_method', 'PUT')
        return this.axios.post(url, data, {headers: {'Content-Type': 'multipart/form-data'}})
    }
}

export default new HttpService()