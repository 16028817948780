<template>
    <div>
        <v-app-bar
            :height="height"
            dense fixed
            flat light
            :class="['toolbar', {'mobile': isMobile}]"
            :style="toolbarColor"
        >
            <v-img :src="imgLogo" eager/>
            <v-spacer/>

            <template v-if="hasCurrentUser && !isMobile">
                <template v-for="menu in menuOptions">
                    <v-menu v-if="!!menu.submenus" :key="menu.to" open-on-hover offset-y rounded="t-0 b-xl">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                :class="['button-toolbar', {'active': isActive(menu.to), 'submenu': isMenuOpen(attrs)}]"
                                depressed text plain
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ menu.name }}
                                <v-icon color="primary">mdi-menu-down</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item v-for="submenu in menu.submenus" :key="submenu.to" link
                                         :class="['submenu-items', {'submenu-item-active': isActive(submenu.to)}]"
                                         @click="goTo({name: submenu.to})"
                            >
                                <v-list-item-title>{{ submenu.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        v-else :key="menu.to" @click="goTo({name: menu.to})"
                        :class="['button-toolbar', {'active': isActive(menu.to)}, {'active-drawer': isActive(menu.to) && !isAdmin}]"
                        depressed text
                    >
                        {{ menu.name }}
                    </v-btn>
                </template>
                <logout-button only-icon/>
            </template>
            <template v-else-if="hasCurrentUser">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
            </template>
        </v-app-bar>
        <v-sheet :height="height"/>
        <v-navigation-drawer v-if="isMobile" v-model="drawer" fixed temporary right>
            <v-divider></v-divider>

            <v-list dense>
                <template v-for="menu in menuOptions">
                    <v-list-group v-if="menu.submenus">
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>{{ menu.name }}</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                            v-for="(submenu, i) in menu.submenus"
                            link
                            @click="goTo({name: submenu.to})"
                            :key="i"
                            :class="['button-toolbar ml-3', {'active-drawer': isActive(submenu.to)}]"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{ submenu.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list-group>

                    <v-list-item v-else :key="menu.name" link @click="goTo({name: menu.to})"
                                 :class="['button-toolbar', {'active-drawer': isActive(menu.to)}]">
                        <v-list-item-content>
                            <v-list-item-title>{{ menu.name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-divider/>
                <v-list-item>
                    <logout-button class="mx-auto"/>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import LogoutButton from "@/components/common/LogoutButton";

export default {
    name: "Toolbar",
    components: {LogoutButton},
    data() {
        return {
            drawer: false
        }
    },
    computed: {
        hasCurrentUser(vm = this) {
            return !!vm.currentUser && vm.$route.name !== 'login'
        },
        height(vm = this) {
            return vm.isMobile ? 60 : 81
        },
        toolbarColor(vm = this) {
            if (!vm.isAdmin && vm.$route.name !== 'login') return 'background: #FFFFFF !important;'
            return undefined;
        },
        menuOptions(vm = this) {
            if (vm.isAdmin) {
                return [
                    {name: 'Inicio', to: 'home'},
                    {
                        name: 'Ejemplo',
                        to: 'admin.example',
                        submenus: [
                            {name: 'Con', to: 'admin.example.with'},
                            {name: 'Menu', to: 'admin.example.menu'},
                        ]
                    },
                ]
            } else {
                if (vm.currentUser.status_id === 1) return []
                return [
                    {name: 'Inicio', to: 'home'},
                    {name: 'Ejemplo', to: 'client.example'},
                ]
            }
        }
    },
    methods: {
        isActive(to) {
            const vm = this
            return vm.$route.name === to || vm.$route.name.includes(to)
        },
        isMenuOpen(attrs) {
            return attrs['aria-expanded'] === 'true'
        }
    }
}
</script>

<style scoped>

.toolbar {
    background: #E2F0CF !important;
    box-shadow: 13px 4px 12px rgba(159, 159, 159, 0.13) !important;
    padding: 0 80px;
}

.mobile {
    padding: 0;
}

>>> .v-btn__content {
    opacity: 1 !important;
}

.button-toolbar {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: none;
    color: #273866 !important;
    border-radius: 20px;
}

.submenu {
    border-radius: 20px 20px 0 0;
}

.submenu-items {
    background: #FFFFFF;
    border-radius: 20px !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #273866;
}

.submenu-item-active {
    background: #E2F0CF;
    border-radius: 20px;
}

.active {
    background: #FFFFFF;
}

.v-application .rounded-b-xl {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.active-drawer {
    background-color: #E2F0CF;
}

</style>