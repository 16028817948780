const clientRoutes = [
    // HOME
    {
        path: '/client/home',
        name: 'client.home',
        component: () => import('@/views/client/Home'),
    },
    // QUESTIONNAIRE
    {
        path: '/client/questionnaire',
        name: 'client.questionnaire',
        component: () => import('@/views/client/ClientQuestionnaire'),
    },
    {
        path: '/client/questionnaire/finished',
        name: 'client.questionnaire.finished',
        component: () => import('@/views/client/ClientQuestionnaireFinished'),
    },
    // EXAMS
    {
        path: '/client/exams',
        name: 'client.exams',
        component: () => import('@/views/client/Exams'),
    },
    {
        path: '/client/exams/finished',
        name: 'client.exams.finished',
        component: () => import('@/views/client/ExamsFinished'),
    },
    // ORDERS
    {
        path: '/client/orders',
        name: 'client.orders',
        component: () => import('@/views/client/OrdersLaboratories'),
    },
];

export default clientRoutes;
