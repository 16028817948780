const doctorRoutes = [
    // HOME
    {
        path: '/doctor/home',
        name: 'doctor.home',
        component: () => import('@/views/doctor/Home'),
    },
    // QUESTIONNAIRE
    {
        path: '/doctor/questionnaire',
        name: 'doctor.questionnaire',
        component: () => import('@/views/doctor/DoctorQuestionnaire'),
    },
    // SIGNATURE
    {
        path: '/doctor/signature',
        name: 'doctor.signature',
        component: () => import('@/views/doctor/DoctorSignature'),
    },
    // QUESTIONNAIRES
    {
        path: '/doctor/questionnaires',
        name: 'doctor.questionnaires',
        component: () => import('@/views/doctor/Questionnaires'),
    },
    {
        path: '/doctor/questionnaires/client/:id',
        name: 'doctor.questionnaires.client',
        component: () => import('@/views/doctor/ClientQuestionnaire'),
    },
    // INVITATIONS
    {
        path: '/doctor/invitations',
        name: 'doctor.invitations',
        component: () => import('@/views/doctor/Invitations'),
    },
    {
        path: '/doctor/invitations/finished',
        name: 'doctor.invitations.finished',
        component: () => import('@/views/doctor/InvitationsFinished'),
    },
    {
        path: '/doctor/invitations/:role',
        name: 'doctor.invitations.create',
        component: () => import('@/views/doctor/InvitationsCreate'),
    },
    // EXAMS
    {
        path: '/doctor/exams',
        name: 'doctor.exams',
        component: () => import('@/views/doctor/Exams'),
    },
    {
        path: '/doctor/exams/:id',
        name: 'doctor.exams.details',
        component: () => import('@/views/doctor/ExamsDetails'),
    },
    {
        path: '/doctor/exams/:id/message/:status',
        name: 'doctor.exams.details.message',
        component: () => import('@/views/doctor/ExamsDetailsMessage'),
    },
    {
        path: '/doctor/client/:id/exams',
        name: 'doctor.client.exams',
        component: () => import('@/views/doctor/ClientUploadExams'),
    },
    // OPERATIONS
    {
        path: '/doctor/operations',
        name: 'doctor.operations',
        component: () => import('@/views/doctor/Operations'),
    },
    // MEDICAL TREATMENTS
    {
        path: '/doctor/medical-treatments',
        name: 'doctor.medical-treatments',
        component: () => import('@/views/doctor/MedicalTreatments'),
    },
    {
        path: '/doctor/clients/:id',
        name: 'doctor.clients.details',
        component: () => import('@/views/doctor/ClientDetails'),
    },
    // PROFILE
    {
        path: '/doctor/profile/edit',
        name: 'doctor.profile.edit',
        component: () => import('@/views/doctor/ProfileEdit'),
    },
    // STATISTICS
    {
        path: '/doctor/statistics',
        name: 'doctor.statistics',
        component: () => import('@/views/doctor/Statistics'),
    },
    // RESPONSES
    {
        path: '/doctor/responses',
        name: 'doctor.responses',
        component: () => import('@/views/doctor/Responses'),
    },
    // TEAM
    {
        path: '/doctor/team',
        name: 'doctor.team',
        component: () => import('@/views/doctor/Team'),
    },
    // LABORATORIES
    {
        path: '/doctor/laboratories',
        name: 'doctor.laboratories',
        component: () => import('@/views/doctor/Laboratories'),
    },
];

export default doctorRoutes;
