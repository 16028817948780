const secretaryRoutes = [
    // HOME
    {
        path: '/secretary/home',
        name: 'secretary.home',
        component: () => import('@/views/secretary/Home'),
    },
    // OPERATIONS
    {
        path: '/secretary/operations',
        name: 'secretary.operations',
        component: () => import('@/views/secretary/Operations'),
    },
    // MEDICAL TREATMENTS
    {
        path: '/secretary/medical-treatments',
        name: 'secretary.medical-treatments',
        component: () => import('@/views/secretary/MedicalTreatments'),
    },
    // CLIENT DETAILS
    {
        path: '/secretary/clients/:id',
        name: 'secretary.clients.details',
        component: () => import('@/views/secretary/ClientDetails'),
    },
    // PROFILE
    {
        path: '/secretary/profile/edit',
        name: 'secretary.profile.edit',
        component: () => import('@/views/secretary/ProfileEdit'),
    },
    // STATISTICS
    {
        path: '/secretary/statistics',
        name: 'secretary.statistics',
        component: () => import('@/views/secretary/Statistics'),
    },
];

export default secretaryRoutes;
