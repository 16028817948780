export const randomString = (length = 6, suffix = '', prefix = '') => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    if (prefix) prefix = `${prefix}_`
    if (suffix) suffix = `_${suffix}`

    return `${prefix}${result}${suffix}`;
}

export const debounce = (func, wait, immediate = false) => {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const getRandomColor = (dark = false) => {
    return dark ? generateDarkColorHex() : generateLightColorHex()
}

export const generateLightColorHex = () => {
    let color = "#";
    for (let i = 0; i < 3; i++)
        color += ("0" + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
    return color;
}

export const generateDarkColorHex = () => {
    let color = "#";
    for (let i = 0; i < 3; i++)
        color += ("0" + Math.floor(Math.random() * Math.pow(16, 2) / 2).toString(16)).slice(-2);
    return color;
}

export const rutValidator = (v) => {
    v = v.replace(/\./g, '').replace(/-/g, '')
    if (!/^[0-9]{7,8}[0-9kK]$/.test(v)) return false
    let rut = v.slice(0, -1);
    let digv = v.slice(-1).toLowerCase()
    return validateDv(rut) + '' === digv
}

const validateDv = (v) => {
    let M = 0, S = 1;
    for (; v; v = Math.floor(v / 10))
        S = (S + v % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : 'k';
}