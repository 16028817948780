import Vue from 'vue'

const moment = require('moment')
require('moment/locale/es')

Vue.filter('date', function (value) {
    return moment(value).format('DD-MM-YYYY')
})

Vue.filter('phone_format', function (value) {
    if (!value) return 'n/d'
    const v = value.split('')
    const a = v[0] + v[1]
    const b = v[2]
    const c = v[3] + v[4] + v[5] + v[6]
    const d = v[7] + v[8] + v[9] + v[10]
    return `+(${a}) ${b} ${c} ${d}`
})

Vue.filter('phone_format_short', function (value) {
    if (!value) return 'n/d'
    const v = value.split('')
    const a = v[0]
    const b = v[1] + v[2] + [3] + v[4]
    const c = v[5] + v[6] + v[7] + v[8]
    return `${process.env.VUE_APP_COUNTRY_PHONE_CODE} ${a} ${b} ${c}`
})

Vue.filter('number', function (number) {
    if (!number) return 0
    return number.toString()
        .replace(/^0/, '')
        .replace(/\./, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
})

Vue.filter('money', function (number) {
    if (!number) return '$0'
    return '$' + number.toString()
        .replace(/^0/, '')
        .replace(/\./, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
})

Vue.filter('rut', function (value) {
    if (!value) return ''
    if (value.length === 1) return value
    let clean = value.replace(/\./g, '').replace(/-/g, '')
    if (clean.length > 9) clean = clean.substring(0, 9)

    const firstSection = clean.substring(0, clean.length - 1).toString()
        .replace(/\./g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")

    const dv = clean.substring(clean.length - 1, clean.length)

    return `${firstSection}-${dv.toUpperCase()}`
})
