<template>
    <div v-if="showWaves">
        <v-img :src="topImg" eager class="top"/>
        <v-img :src="require('@/assets/img/background_bottom.png')" eager class="bottom"/>
    </div>
</template>

<script>
export default {
    name: "waves",
    props: {
        force: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            routes: [
                'splash',
                'login',
                'doctor.register',
            ],
            routesDark: [
                'client.home',
                'client.exams.finished',
                'doctor.register',
                'doctor.signature',
                'doctor.invitations.finished'
            ]
        }
    },
    mounted() {
        this.$emit('show', this.showWaves)
    },
    computed: {
        topImg() {
            return this.routesDark.includes(this.$route.name) ?
                require('@/assets/img/background_top_white.png') :
                require('@/assets/img/background_top.png')
        },
        showWaves() {
            return !this.routes.includes(this.$route.name) || this.force
        },
    },
    watch: {
        showWaves(val) {
            this.$emit('show', val)
        }
    }
}
</script>

<style scoped>

.top {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 100px;
    z-index: 0;
}

.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100px;
    z-index: 0;
}

</style>