import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import './main.sass'
import './registerServiceWorker'
import './utils/Filters'

import {VueMaskDirective} from 'v-mask'

Vue.directive('mask', VueMaskDirective);

import VueMoment from 'vue-moment'

const moment = require('moment')
require('moment/locale/es')
Vue.use(VueMoment, {
    moment
})

import {globalMixin} from '@/mixins/globalMixin'

Vue.mixin(globalMixin)

import {defineCustomElements} from '@ionic/pwa-elements/loader';

defineCustomElements(window)

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
