<template>
    <v-btn :color="color" :block="block" x-large depressed class="normal-btn" @click="onLogout">
        Salir
    </v-btn>
</template>

<script>

import {mapActions} from "vuex";

export default {
    name: "LogoutButton",
    props: {
        color: {
            type: String,
            default: 'primary'
        },
        block: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        ...mapActions({logout: 'auth/logout'}),
        onLogout() {
            const vm = this
            vm.logout()
            vm.goTo({name: 'login'})
        }
    },
}
</script>

<style scoped>

</style>