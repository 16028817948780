export const ROLES = {
    DOCTOR: 1,
    ASSISTANT: 2,
    SECRETARY: 3,
    CLIENT: 4,
}

export const STATUS = {
    REJECT: 'reject',
    APPROVE: 'approve'
}

export const DOCUMENTS = {
    AVATAR: 1,
    SIGNATURE: 2,
    ENDOSCOPY: 3,
    LABORATORY: 4,
    CARDIOLOGICAL: 5,
    SONOGRAPHY: 6,
    NUTRITIONAL_PASS: 7,
    PSYCHOLOGICAL_PASS: 8
}