const assistantRoutes = [
    // HOME
    {
        path: '/assistant/home',
        name: 'assistant.home',
        component: () => import('@/views/assistant/Home'),
    },
    // QUESTIONNAIRES
    {
        path: '/assistant/questionnaires',
        name: 'assistant.questionnaires',
        component: () => import('@/views/assistant/Questionnaires'),
    },
    {
        path: '/assistant/questionnaires/client/:id',
        name: 'assistant.questionnaires.client',
        component: () => import('@/views/assistant/ClientQuestionnaire'),
    },
    // EXAMS
    {
        path: '/assistant/exams',
        name: 'assistant.exams',
        component: () => import('@/views/assistant/Exams'),
    },
    {
        path: '/assistant/exams/:id',
        name: 'assistant.exams.details',
        component: () => import('@/views/assistant/ExamsDetails'),
    },
    {
        path: '/assistant/exams/:id/message/:status',
        name: 'assistant.exams.details.message',
        component: () => import('@/views/assistant/ExamsDetailsMessage'),
    },
    {
        path: '/assistant/client/:id/exams',
        name: 'assistant.client.exams',
        component: () => import('@/views/assistant/ClientUploadExams'),
    },
    // OPERATIONS
    {
        path: '/assistant/operations',
        name: 'assistant.operations',
        component: () => import('@/views/assistant/Operations'),
    },
    // MEDICAL TREATMENTS
    {
        path: '/assistant/medical-treatments',
        name: 'assistant.medical-treatments',
        component: () => import('@/views/assistant/MedicalTreatments'),
    },
    {
        path: '/assistant/clients/:id',
        name: 'assistant.clients.details',
        component: () => import('@/views/assistant/ClientDetails'),
    },
    // PROFILE
    {
        path: '/assistant/profile/edit',
        name: 'assistant.profile.edit',
        component: () => import('@/views/assistant/ProfileEdit'),
    },
    // STATISTICS
    {
        path: '/assistant/statistics',
        name: 'assistant.statistics',
        component: () => import('@/views/assistant/Statistics'),
    },
    // RESPONSES
    {
        path: '/assistant/responses',
        name: 'assistant.responses',
        component: () => import('@/views/assistant/Responses'),
    },
];

export default assistantRoutes;
