import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import doctorRouter from "@/router/doctor";
import clientRoutes from "@/router/client";
import errorRoutes from "@/router/errorRoutes";
import middlewares from "@/router/middlewares";
import secretaryRoutes from "@/router/secretary";
import assistantRoutes from "@/router/assistant";

Vue.use(VueRouter)

const middleware = handler => (
    routes => routes.map(route => Object.assign({}, route, {beforeEnter: handler}))
);

const routes = [
    ...middleware(middlewares.auth(store))([
        ...doctorRouter,
        ...assistantRoutes,
        ...secretaryRoutes,
        ...clientRoutes,
    ]),
    ...errorRoutes,
    {
        path: '/',
        name: 'splash',
        component: () => import('@/views/entrance/Splash'),
        meta: {
            public: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/entrance/Login'),
        meta: {
            public: true,
        }
    },
    // REGISTER
    {
        path: '/doctor/register',
        name: 'doctor.register',
        component: () => import('@/views/doctor/DoctorRegister'),
        meta: {
            public: true,
        }
    },
    {
        path: '/client/:id/register',
        name: 'client.register',
        component: () => import('@/views/client/ClientRegister'),
        meta: {
            public: true,
        }
    },
    // RESET PASSWORD
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/entrance/ResetPassword'),
        meta: {
            public: true,
        }
    },
]

export default new VueRouter({
    routes,
    mode: 'history'
})
