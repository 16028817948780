<template>
    <v-sheet color="transparent" class="border-black" :height="height"/>
</template>

<script>
export default {
    name: "Spacer",
    props: {
        height: {
            type: [String, Number],
            default: 100,
        }
    }
}
</script>

<style scoped>

</style>