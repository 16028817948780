import {mapState, mapMutations, mapGetters} from 'vuex'
import {randomString} from '@/utils/Common'

export const globalMixin = {
    computed: {
        ...mapState('auth', {
            currentUser: state => state.currentUser,
        }),
        ...mapState(['dialogActive']),
        ...mapGetters('auth',
            ['isDoctor', 'isAssistant', 'isSecretary', 'isClient', 'isLoggedIn']
        ),
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown
        },
        appName() {
            return process.env.VUE_APP_NAME
        },
        imgIcon() {
            return require('@/assets/logos/logo.png')
        },
        imgLogo() {
            return require('@/assets/logos/logo_light.png')
        },
        imgLogoDark() {
            return require('@/assets/logos/logo_dark.png')
        },
        phoneCode() {
            return process.env.VUE_APP_COUNTRY_PHONE_CODE + ' ' || ''
        },
        baseUrl() {
            return process.env.VUE_APP_BASE_URL || ''
        },
        baseUrlFront() {
            return process.env.VUE_APP_BASE_URL_FRONT || ''
        },
        accountType() {
            return this.currentUser?.role?.value || null
        },
    },
    methods: {
        ...mapMutations(['showSnackBar', 'hideSnackBar', 'setDialogActive']),
        randomString,
        toast(message = '', color = 'success', closeColor = 'white') {
            const vm = this
            vm.showSnackBar({text: message, color: color, closeColor: closeColor})
        },
        goHome() {
            const vm = this
            if (!vm.accountType) return vm.goReplace({name: 'login'})
            return vm.goTo({name: `${vm.accountType}.home`})
        },
        goBack() {
            const vm = this
            return vm.$router.back()
        },
        goTo(route) {
            const vm = this
            return vm.$router.push(route)
        },
        goReplace(route) {
            const vm = this
            return vm.$router.replace(route)
        },
        getIdsFromArray(array, attribute, value) {
            if (!attribute && !value) return array.map(item => (item.id))
            return array.filter(item => item[attribute] === value).map(item => (item.id))
        },
        getFilePath(path, name) {
            const vm = this
            return `${vm.baseUrl}${path}/${name}`
        }
    },
}
