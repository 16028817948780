import SecureLS from 'secure-ls'

const ls = new SecureLS();

export const storageService = {
    keyName: 'cxsmart_token',
    get(key) {
        return ls.get(`${process.env.VUE_APP_NAME_ID}_${key}`);
    },
    set(key, value) {
        return ls.set(`${process.env.VUE_APP_NAME_ID}_${key}`, value);
    },
    getToken() {
        return this.get(this.keyName);
    },
    setToken(token) {
        return this.set(this.keyName, token);
    },
    hasToken() {
        return !!this.get(this.keyName);
    },
    removeToken() {
        return ls.remove(this.keyName);
    }
};

export default storageService;