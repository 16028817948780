<template>
    <v-app>
        <toolbar v-if="toolbar"/>
        <v-main :class="[{'full-background': fullBackground}]">
            <waves @show="needSpacer = $event"/>
            <transition name="main">
                <router-view/>
            </transition>
            <spacer v-if="needSpacer"/>
        </v-main>
        <kimsa-toast/>
    </v-app>
</template>

<script>
import Toolbar from "@/components/common/Toolbar";
import KimsaToast from "@/components/common/KimsaToast";
import Waves from "@/components/layouts/Waves";
import Spacer from "@/components/layouts/Spacer";

export default {
    name: 'App',
    components: {
        Spacer,
        Waves,
        KimsaToast,
        Toolbar,
    },
    created() {
        const vm = this
        setTimeout(() => {
            vm.loadUser()
        }, 500)
    },
    data() {
        return {
            needSpacer: false,
        }
    },
    computed: {
        meta() {
            return this.$route?.meta || null
        },
        toolbar() {
            return this.meta?.toolbar || false
        },
        footer() {
            return this.meta?.footer || false
        },
        public() {
            return this.meta?.public || false
        },
        fullBackground() {
            let routes = [
                'splash',
                'login',
                'client.home',
                'client.exams.finished',
                'doctor.register',
                'doctor.signature',
                'doctor.invitations.finished'
            ]
            return routes.includes(this.$route.name)
        },
    },
    methods: {
        async loadUser() {
            const vm = this
            if (!vm.public)
                await vm.$store.dispatch('auth/fetchAuthenticatedUser').catch(err => {
                    vm.$store.dispatch('auth/refreshToken')
                })
        },
    },
    watch: {
        currentUser(val) {
            // console.log('app currentUser', val)
        }
    }
}
</script>

<style>

.full-background {
    background-color: var(--v-primary-base);
}

</style>