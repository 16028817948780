import storageService from "@/services/storageService"
import httpService from "@/services/httpService"
import {ROLES} from "@/utils/Constants";

export default {
    namespaced: true,
    state: {
        //User
        currentUser: null,
        isLoggedIn: false,
        token: '',
    },
    mutations: {
        userLogin: (state, response) => {
            storageService.setToken(response.token.access_token)
            state.token = response.token.access_token
            state.isLoggedIn = true
        },

        userLogout: (state) => {
            storageService.removeToken()
            state.token = ''
            state.currentUser = null
            state.isLoggedIn = false
        },

        setCurrentUser: (state, user) => {
            state.isLoggedIn = true
            state.currentUser = user
        },
    },
    actions: {
        login: (context, user) => {
            return new Promise((resolve, reject) => {
                httpService.post('auth/login', {
                    email: user.email,
                    password: user.password,
                })
                    .then(async res => {
                        if (res.data.error) return resolve(res)
                        context.commit('userLogin', res.data)
                        await context.dispatch('fetchAuthenticatedUser')
                        resolve(res)
                    }).catch(err => {
                    reject(err.response.data)
                })
            })
        },

        logout: (context) => {
            return new Promise((resolve, reject) => {
                httpService.post('auth/logout')
                    .then(res => {
                        context.commit('userLogout')
                        resolve(res)
                    }).catch(err => {
                    context.commit('userLogout')
                    reject(err)
                })
            })
        },

        refreshToken: (context) => {
            return new Promise((resolve, reject) => {
                httpService.post('/auth/refresh')
                    .then(res => {
                        if (res.data?.token?.access_token) context.commit('userLogin', res.data)
                        resolve(res)
                    }).catch(err => {
                    reject(err)
                })
            })
        },

        fetchAuthenticatedUser: (context) => {
            return new Promise((resolve, reject) => {
                httpService.get('/auth/me')
                    .then(res => {
                        context.commit('setCurrentUser', res.data.user)
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        registerClient: (context, payload) => {
            return new Promise((resolve, reject) => {
                httpService.post(`auth/client/${payload.doctor_id}/register`, payload)
                    .then(async res => {
                        context.commit('userLogin', res.data)
                        await context.dispatch('fetchAuthenticatedUser')
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response.data.email) resolve({error: 'Email ya existe.'})
                        if (err.response.data.rut) resolve({error: 'Rut ya existe.'})
                        reject(err.response.data)
                    })
            })
        },

        registerDoctor: (context, payload) => {
            return new Promise((resolve, reject) => {
                httpService.post(`auth/doctor/register`, payload)
                    .then(async res => {
                        context.commit('userLogin', res.data)
                        await context.dispatch('fetchAuthenticatedUser')
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response.data.email) resolve({error: 'Email ya existe.'})
                        if (err.response.data.rut) resolve({error: 'Rut ya existe.'})
                        reject(err.response.data)
                    })
            })
        },
    },
    getters: {
        hasToken: () => storageService.hasToken(),
        isLoggedIn: (state) => state.isLoggedIn,
        getCurrentUser: (state) => state.currentUser,
        getToken: () => storageService.getToken(),
        isDoctor: (state) => state.currentUser?.role_id === ROLES.DOCTOR,
        isAssistant: (state) => state.currentUser?.role_id === ROLES.ASSISTANT,
        isSecretary: (state) => state.currentUser?.role_id === ROLES.SECRETARY,
        isClient: (state) => state.currentUser?.role_id === ROLES.CLIENT,
        clientCompletedQuiz: (state) => state.currentUser?.completed_quiz || false,
        hasSignature: (state) => state.currentUser?.signature || null
    }
}