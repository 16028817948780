import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    iconfont: 'mdi',
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#172587',
                secondary: '#7C8AED',
                ternary: '#afdfef',
                accent: '#7b89eb',
                highlight: '#1E233A',
            },
        },
    },
    icons: {
        values: {
            back: {component: () => import('@/assets/icons/back')},
            camera: {component: () => import('@/assets/icons/camera')},
            check: {component: () => import('@/assets/icons/check')},
            check_circle: {component: () => import('@/assets/icons/check_circle')},
            download: {component: () => import('@/assets/icons/download')},
            edit: {component: () => import('@/assets/icons/edit')},
            edit_square: {component: () => import('@/assets/icons/edit_square')},
            cardiological: {component: () => import('@/assets/icons/cardiological')},
            sonography: {component: () => import('@/assets/icons/sonography')},
            email: {component: () => import('@/assets/icons/email')},
            endoscopy: {component: () => import('@/assets/icons/endoscopy')},
            exams: {component: () => import('@/assets/icons/exams')},
            eye: {component: () => import('@/assets/icons/eye')},
            heart_signal: {component: () => import('@/assets/icons/heart_signal')},
            home: {component: () => import('@/assets/icons/home')},
            human: {component: () => import('@/assets/icons/human')},
            image: {component: () => import('@/assets/icons/image')},
            invoice: {component: () => import('@/assets/icons/invoice')},
            knife: {component: () => import('@/assets/icons/knife')},
            laboratory: {component: () => import('@/assets/icons/laboratory')},
            logout: {component: () => import('@/assets/icons/logout')},
            man: {component: () => import('@/assets/icons/man')},
            menu: {component: () => import('@/assets/icons/menu')},
            min_age: {component: () => import('@/assets/icons/min_age')},
            next: {component: () => import('@/assets/icons/next')},
            phone: {component: () => import('@/assets/icons/phone')},
            profile_circle: {component: () => import('@/assets/icons/profile_circle')},
            response: {component: () => import('@/assets/icons/response')},
            secretary: {component: () => import('@/assets/icons/secretary')},
            statistics: {component: () => import('@/assets/icons/statistics')},
            team: {component: () => import('@/assets/icons/team')},
            upload: {component: () => import('@/assets/icons/upload')},
            user: {component: () => import('@/assets/icons/user')},
            woman: {component: () => import('@/assets/icons/woman')},
        }
    }
});
